@charset 'UTF-8';
@import './main/src/constants';
@import './main/src/type';

::selection {
  background: transparent;
  color: inherit;
  text-shadow: none;
}

figure {
  margin: 0;
}

.main-footer .cms-html {
  @include signifier(21px, 11px, 21px / $design-max * $design-max);

  @include desktop-and-tablet-landscape {
    padding-top: $desktop-margin;
    padding-bottom: $desktop-margin;
  }

  @include tablet-portrait {
    padding-top: $tablet-margin;
    padding-bottom: $tablet-margin;
  }

  @include phone {
    padding-top: $phone-margin;
    padding-bottom: $phone-margin;
  }

  p {
    line-height: 1.3;

    a {
      @include inverse-anchor-style;
    }
  }
}
